import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

// import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/styles.min.css"

class Maintenance extends Component {
  render() {
    const { data } = this.props
    const maintenance = data.contentfulMaintenanceMessage

    return (
      <>
        <SEO pageTitle={maintenance.title} />
        {/* <Image imagePath="5.jpg" /> */}
        <div className="page-maintenance">
          <div className="container">
            <img
              style={{ width: "150px", marginBottom: "20px" }}
              src={maintenance.image.file.url}
              alt={maintenance.image.title}
            ></img>
            <h1>{maintenance.title}</h1>
            <div className="message">
              {documentToReactComponents(
                maintenance.childContentfulMaintenanceMessageMessageRichTextNode
                  .json
              )}
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        contentfulMaintenanceMessage {
          title
          image {
            title
            file {
              url
            }
          }
          childContentfulMaintenanceMessageMessageRichTextNode {
            json
          }
        }
      }
    `}
    render={data => <Maintenance data={data} {...props} />}
  />
)
